import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'

export function useAccessToken(): [string] {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const [token, setToken] = useState('')
  useEffect(() => {
    let interval: number
    const getToken = () => {
      getAccessTokenSilently()
        .then(fetchedToken => {
          setToken(fetchedToken)
          const tokenPayload: { exp: number } = jwtDecode(fetchedToken)
          interval = window.setTimeout(getToken, tokenPayload.exp * 1000 - new Date().getTime() - 1000) // get token 1 second before expiry
        })
        .catch(err => {
          if (err.error === 'login_required') {
            loginWithRedirect()
          }
          if (err.error === 'consent_required') {
            loginWithRedirect()
          }
          throw err
        })
    }

    getToken()
    return () => {
      interval && clearTimeout(interval)
    }
  }, [loginWithRedirect, getAccessTokenSilently])
  return [token]
}
