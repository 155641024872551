import React, { useState } from 'react'
import { Modal, Button, message, Form } from 'antd'
import styled from 'styled-components'
import { ModalProps } from 'antd/lib/modal'
import { LabeledValue } from 'antd/lib/select'
import FormItem from 'antd/es/form/FormItem'
import {
  CreateOrganizationInput,
  Customer,
  Organization,
  useAddOrganizationToCustomer,
  useCreateOrganizationMutation,
} from '../../../../api'
import { OrganizationsSelector } from '../../../components/selectors/OrganizationsSelector'
import { CreateEditOrganizationForm } from '../../forms/CreateEditOrganizationForm'

type NewOrganizationFormProps = ModalProps & {
  onFinished: (newOrganization?: Organization) => any
  onError?: (error: Error) => any
  customer: Customer
  parentId?: string
}

export const AddOrganizationToCustomerModal: React.FC<NewOrganizationFormProps> = ({
  onFinished,
  onError,
  customer,
  parentId,
  ...props
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>()

  const [isCreatingMode, setIsCreatingMode] = useState(true)

  const [createOrganization, { data: createdOrganizationData }] = useCreateOrganizationMutation()
  const [addOrganizationToCustomer] = useAddOrganizationToCustomer()

  const [form] = Form.useForm()

  const handleOnOk = async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }

    const input = form.getFieldsValue()

    const orgInput: CreateOrganizationInput = {
      ...input,
      parentId: input.parentId || undefined,
      name: input.name || '',
      facilityId: Number(input.facilityId) || undefined,
      customerIds: [customer.id],
      address: input.address,
      code: input.code || '',
    }

    const promise = isCreatingMode
      ? createOrganization({ variables: { input: orgInput } }).then(() => {
          message.success('Created new Organization')
          onFinished(createdOrganizationData?.createOrganization)
        })
      : addOrganizationToCustomer({
          variables: {
            input: {
              organizationId: selectedOrganizationId!,
              customerId: customer.id,
            },
          },
        }).then(result => {
          message.success('Added new Organization')
          if (result.data && result.data.addOrganizationToCustomer) {
            onFinished(result.data.addOrganizationToCustomer)
          }
        })
    promise.catch(e => {
      if (onError) {
        onError(e)
      } else {
        message.error(e.message)
      }
    })
  }

  return (
    <Modal
      title={`Add Organization to ${customer ? customer.name : 'Client'}`}
      {...props}
      onOk={handleOnOk}
      okText={isCreatingMode ? 'Create Organization' : 'Add to Client'}>
      <LabelTextWrapper>
        <LabelText>{isCreatingMode ? 'Create New Organization' : 'Select Existing Organization'}</LabelText>
        <Button onClick={() => setIsCreatingMode(!isCreatingMode)}>
          {isCreatingMode ? 'Select Existing...' : 'Create New'}
        </Button>
      </LabelTextWrapper>
      {isCreatingMode ? (
        <CreateEditOrganizationForm parentId={parentId} form={form} customer={customer} />
      ) : (
        <FormItem label="Select Organization" required>
          <OrganizationsSelector
            style={{ width: '100%' }}
            onChange={v => setSelectedOrganizationId((v as LabeledValue).value.toString())}
          />
        </FormItem>
      )}
    </Modal>
  )
}

const LabelText = styled.div`
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

const LabelTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`
