export const calcElementHeight = (ref: React.MutableRefObject<any>) => {
  const rect = ref.current.getBoundingClientRect()
  return `${window.innerHeight - rect.y - 48}px`
}

export async function parallelHandler<T>(
  promiseFunction: (index: number, prev: T) => Promise<T>,
  maxConnections: number,
  hasNextFn: (index: number, prev: T) => boolean,
) {
  const executors = Array(maxConnections).fill(Promise.resolve())

  const chainPromise = async (p: Promise<any>, index: number): Promise<any> => {
    p.catch(e => console.info(e))
    const prev = await p
    if (hasNextFn(index, prev)) {
      return chainPromise(promiseFunction(index, prev), index)
    }
    return prev
  }

  await Promise.all(executors.map(chainPromise).map(p => p.catch(e => console.info(e))))
}

export function isPromise(p: any) {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true
  }

  return false
}

export function stringCaseInsensitiveComparator(a?: string, b?: string) {
  const aLower = (a || '').toLowerCase()
  const bLower = (b || '').toLowerCase()
  if (aLower === bLower) {
    return 0
  }
  return aLower < bLower ? -1 : 1
}

export const pathJoin = (parts: string[], sep = '/') =>
  parts
    .join(sep)
    .replace(new RegExp(sep + '{1,}', 'g'), sep)
    .replace(':/', '://') // restore the http(s):// prefix
