import { useAbility } from '@casl/react'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { MeContext, UserPermissionFeature } from '../../api'
import { AbilityContext } from '../../api/components/Can'
import { CategoriesSection } from './Categories/Categories'
import { FeaturedContentCarousel } from './components/FeaturedContent'
import { ContractsSection } from './Contracts/Contracts'
import { OpportunitiesSection } from './Opportunities/Opportunities'
import { PlaneIssues } from './PlaneIssues/PlaneIssues'

export const Home: React.FC<{}> = () => {
  const { data } = useContext(MeContext)
  const ability = useAbility(AbilityContext)

  const navigate = useNavigate()
  useEffect(() => {
    if (data?.me?.isAdmin) {
      navigate('/manage/customer')
    }
  })

  return (
    <Wrapper>
      <Helmet>
        <title>Curvo</title>
      </Helmet>
      <FeaturedContentCarousel />
      {ability.can('access', UserPermissionFeature.ProjectAperture) ? <PlaneIssues /> : null}
      <CategoriesSection />
      <OpportunitiesSection />
      <ContractsSection />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px 32px;
`
