export default {
  api: 'https://cx-api.curvolabs.com/graphql',
  agGridLicense:
    'Using_this_AG_Grid_Enterprise_key_( AG-047608 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Curvo Labs, Inc. )_is_granted_a_( Multiple Applications )_Developer_License_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 9 November 2024 )____[v2]_MTczMTExMDQwMDAwMA==4f52ef173e2e4be70812a16ef0557401',
  supplierPortalBaseUrl: 'https://activate.curvolabs.com',
  authDomain: 'curvo.us.auth0.com',
  authClientId: 'Yuymo7QivJOJqhPxC41yna0cu3Ck4BQx',
  authAudience: 'https://curvo.us.auth0.com/api/v2/',
  useHubspot: true,
  useHeap: true,
  launchDarklyClientId:
    '642a8c28bdd73812d6f1d334',
  planeHostUrl: 'https://plane.curvo.cloud',
}
