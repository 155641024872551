import React, { useContext, useEffect, useMemo } from 'react'
import { Form, Input, Row, Col, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import FormItem from 'antd/es/form/FormItem'
import { Customer, MeContext, useCustomerQuery } from '../../../api'
import { useOrganizationTypesQuery } from '../../../api/hooks/organization-types'
import { StateSelector } from '../../components/selectors/StateSelector'

export const CreateEditOrganizationForm: React.FC<{
  isEditing?: boolean
  form: FormInstance
  customer: Customer
  parentId?: string
}> = ({ form, parentId, isEditing, customer }) => {
  const orgTypesRequest = useOrganizationTypesQuery({ variables: { input: {} } })
  const { data: customerData, loading } = useCustomerQuery({ variables: { input: { id: customer.id } } })
  const orgTypes = orgTypesRequest && orgTypesRequest.data ? orgTypesRequest.data.organizationTypes : []
  const orgIds = useMemo(() => customerData?.customer.organizations.map(org => org.id) || [], [customerData])

  const { data: meData } = useContext(MeContext)
  const isCurvoAdmin = meData?.me.isAdmin || false

  useEffect(() => {
    if (!isEditing) {
      form.setFieldsValue({ parentId: parentId || '' })
    }
  }, [isEditing, form, parentId])

  const currentParentId = form.getFieldValue('parentId')

  return (
    <Form form={form} size="middle" layout="vertical">
      {(!currentParentId || orgIds.includes(currentParentId)) && (
        <FormItem name="parentId" label="Parent Organization">
          <Select showSearch loading={loading}>
            <Select.Option key="" value="">
              Top Level
            </Select.Option>
            {customerData?.customer.organizations.map(org => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      )}
      <FormItem
        name="organizationTypeId"
        label="Organization Type"
        rules={[{ required: true, message: 'Please select the organization type' }]}>
        <Select showSearch>
          {orgTypes.map(orgType => (
            <Select.Option key={orgType.id} value={orgType.id}>
              {orgType.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label="Organization Name"
        key="name"
        name="name"
        rules={[{ required: true, message: 'Please input the organization name' }]}>
        <Input />
      </FormItem>
      {isCurvoAdmin && (
        <FormItem label="Facility ID" key="facilityId" name="facilityId">
          <Input />
        </FormItem>
      )}
      <FormItem label="Address 1" key="address" name="address">
        <Input />
      </FormItem>
      <FormItem label="Address 2" key="address2" name="address2">
        <Input />
      </FormItem>
      <Row gutter={8}>
        <Col span={8}>
          <FormItem label="City" key="city" name="city">
            <Input />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="State" key="state" name="state">
            <StateSelector allowClear />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Zip Code" key="zipCode" name="zipCode">
            <Input />
          </FormItem>
        </Col>
      </Row>
      <FormItem label="Phone Number" key="phoneNumber" name="phoneNumber">
        <Input />
      </FormItem>
      <FormItem label="Medicare ID" key="medicareId" name="medicareId">
        <Input />
      </FormItem>
      <FormItem label="Geocode" key="geoCode" name="geoCode">
        <Input />
      </FormItem>
      <FormItem label="Code" key="code" name="code">
        <Input />
      </FormItem>
      <FormItem label="Website" key="website" name="website">
        <Input />
      </FormItem>
      <FormItem label="Description" key="description" name="description">
        <Input.TextArea rows={3} />
      </FormItem>
    </Form>
  )
}
