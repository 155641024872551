import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query, QueryPlaneIssuesArgs } from '../../gen/models'

export const PlaneIssueFragment = gql`
  fragment PlaneIssueFragment on PlaneIssue {
    id
    name
    sequence_id
    created_at
    project {
      id
      name
      identifier
    }
    state {
      id
      name
      color
    }
  }
`

const PLANE_ISSUES_QUERY = gql`
  query planeIssues($args: PlaneIssueArgs!) {
    planeIssues(args: $args) {
      results {
        ...PlaneIssueFragment
      }
    }
  }
  ${PlaneIssueFragment}
`

export function usePlaneIssuesQuery(options: QueryHookOptions<Pick<Query, 'planeIssues'>, QueryPlaneIssuesArgs>) {
  return useQuery(PLANE_ISSUES_QUERY, options)
}
