import axios from 'axios'

import { useEffect, useState } from 'react'
import { useAccessToken } from '../../../common/hooks/auth'
import { pathJoin } from '../../../common/util'

// @ts-ignore
import Env from '../../../api/gen/Env'

type PlaneUser = {
  id: string
}

const doFetchPlaneMe = async (token: string): Promise<PlaneUser> => {
  const result = await axios.get(pathJoin([Env.planeHostUrl, 'api/users/me/']), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return result.data
}

export function useLoadPlaneMe() {
  const [planeMe, setPlaneMe] = useState<PlaneUser | null>(null)
  const [accessToken] = useAccessToken()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (accessToken && !planeMe) {
      setLoading(true)
      doFetchPlaneMe(accessToken)
        .then(me => {
          setPlaneMe(me)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => setLoading(false))
    }
  }, [accessToken, planeMe])

  return { loading, planeMe }
}
