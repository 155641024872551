import { Statistic } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PlaneIssue } from '../../../api'

const DateFormat = new Intl.DateTimeFormat('en', { month: 'short', day: '2-digit', year: 'numeric' })

export const PlaneIssueCard: React.FC<{ issue: PlaneIssue }> = ({ issue }) => {
  return (
    <ListItemCard>
      <TitleWrapper>
        <Title to={`aperture/collaboration/projects/${issue.project.id}/issues/${issue.id}`}>{issue.name}</Title>
        <IssueState color={issue.state.color}>{issue.state.name}</IssueState>
      </TitleWrapper>
      <InfoWrapper>
        <Statistic title="Code" value={`${issue.project.identifier}-${issue.sequence_id}`} />
        <Statistic title="Project" value={issue.project.name} />
        <Statistic title="Created At" value={DateFormat.format(new Date(issue.created_at))} />
      </InfoWrapper>
    </ListItemCard>
  )
}

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-statistic-content-value {
    width: 100%;
    word-wrap: break-word;
  }
`

const ListItemCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 20px 32px;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 4px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 8px;
  text-wrap: nowrap;
`

const Title = styled(Link)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #3b7cff;
`

const IssueState = styled.div<{ color: string }>`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  // height: 18px;
  text-align: center;
`
