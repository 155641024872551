import { DeleteOutlined, EnterOutlined } from '@ant-design/icons'
import { Button, Col, Popconfirm, Radio, Row, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Organization, OrganizationPermission } from '../../api'

export const OrganizationsPermissionsSelector: React.FC<{
  organizations: Organization[]
  loading?: boolean
  value?: OrganizationPermission[]
  disabled?: boolean
  onChange?: (value: OrganizationPermission[]) => void
}> = ({ value, organizations: customerOrganizations, loading, onChange, disabled }) => {
  const [selectedOrganizationPermissions, setSelectedOrganizationPermissions] = useState<OrganizationPermission[]>(
    value || [],
  )

  useEffect(() => {
    if (value) {
      setSelectedOrganizationPermissions(value)
    }
  }, [value])

  const organizations = useMemo(
    () =>
      customerOrganizations.filter(
        org =>
          !selectedOrganizationPermissions ||
          !selectedOrganizationPermissions.find(selected => selected.organizationId === org.id),
      ) || [],
    [customerOrganizations, selectedOrganizationPermissions],
  )

  const rootOrgs = organizations.filter(org => !org.allParents.length)

  return (
    <>
      <Select
        showSearch
        loading={loading}
        style={{ width: '100%' }}
        optionLabelProp="label"
        value={[]}
        disabled={disabled}
        filterOption={(input, opt) => {
          const lcInput = input.toLocaleLowerCase()
          const organization: Organization = (opt!.children as any).props.organization
          return !!(
            organization.name.toLocaleLowerCase().indexOf(lcInput) >= 0 ||
            (organization.description && organization.description.toLocaleLowerCase().indexOf(lcInput) >= 0) ||
            (organization.address && organization.address.toLocaleLowerCase().indexOf(lcInput) >= 0) ||
            (organization.address2 && organization.address2.toLocaleLowerCase().indexOf(lcInput) >= 0)
          )
        }}>
        {organizations.map(org => (
          <Select.Option key={org.id} value={org.id} title={org.name} label={org.name}>
            <OrganizationSelectOption
              organization={org}
              onSelect={() => {
                const newValue = [...selectedOrganizationPermissions, { organizationId: org.id, isPowerUser: false }]
                setSelectedOrganizationPermissions(newValue)
                onChange && onChange(newValue)
              }}
            />
          </Select.Option>
        ))}
      </Select>
      <SelectAllRoot>
        <SelectAllRootButton
          disabled={!rootOrgs.length}
          type="link"
          onClick={() => {
            if (rootOrgs.length) {
              const newValue = rootOrgs.map(org => ({ organizationId: org.id, isPowerUser: false }))
              setSelectedOrganizationPermissions(newValue)
              onChange && onChange(newValue)
            }
          }}>
          Select all root organizations
        </SelectAllRootButton>
      </SelectAllRoot>
      <OrganizationListWrapper>
        <OrganizationList>
          {customerOrganizations
            .filter(org => !!selectedOrganizationPermissions.find(selection => selection.organizationId === org.id))
            .sort((a, b) => {
              const nameA = [...a.allParents, a]
                .map(parent => parent.name)
                .join(' / ')
                .toLocaleLowerCase()
              const nameB = [...b.allParents, b]
                .map(parent => parent.name)
                .join(' / ')
                .toLocaleLowerCase()

              if (nameA > nameB) {
                return 1
              }

              if (nameA < nameB) {
                return -1
              }

              return 0
            })
            .map(org => {
              const selectedOrg = selectedOrganizationPermissions.find(selection => selection.organizationId === org.id)
              return (
                <OrganizationPermissionListItem
                  key={org.id}
                  disabled={disabled}
                  organization={org}
                  isAdmin={selectedOrg ? selectedOrg.isPowerUser : false}
                  onDeleteButtonClicked={() => {
                    const newValue = selectedOrganizationPermissions.filter(
                      selection => selection.organizationId !== org.id,
                    )
                    setSelectedOrganizationPermissions(newValue)
                    onChange && onChange(newValue)
                  }}
                  onRoleChanged={val => {
                    const newValue = [
                      ...selectedOrganizationPermissions.filter(selection => selection.organizationId !== org.id),
                      { organizationId: org.id, isPowerUser: val },
                    ]
                    setSelectedOrganizationPermissions(newValue)
                    onChange && onChange(newValue)
                  }}
                />
              )
            })}
        </OrganizationList>
      </OrganizationListWrapper>
    </>
  )
}

type OrganizationUserItemListProps = {
  organization: Organization
  width?: string
  onDeleteButtonClicked: () => any
  onRoleChanged: (isAdmin: boolean) => any
  isAdmin?: boolean
  disabled?: boolean
}

const OrganizationPermissionListItem: React.FC<OrganizationUserItemListProps> = ({
  organization,
  isAdmin,
  onRoleChanged,
  onDeleteButtonClicked,
  disabled,
}) => (
  <Row>
    <Col span={14}>{[...organization.allParents, organization].map(parent => parent.name).join(' / ')}</Col>
    <Col span={8}>
      <OrganizationUserRoleRadioGroup
        name={`${organization.id} role`}
        value={isAdmin ? 1 : 0}
        disabled={disabled}
        onChange={v => {
          onRoleChanged(!!v.target.value)
        }}>
        <RoleRadio value={1}>Admin</RoleRadio>
        <RoleRadio value={0}>General User</RoleRadio>
      </OrganizationUserRoleRadioGroup>
    </Col>
    <Col span={2}>
      <OrganizationUserBtnDeleteWrapper>
        <Popconfirm title="Do you want to remove this organization?" onConfirm={onDeleteButtonClicked}>
          <RemoveUserButton shape="circle">
            <DeleteOutlined />
          </RemoveUserButton>
        </Popconfirm>
      </OrganizationUserBtnDeleteWrapper>
    </Col>
  </Row>
)

const OrganizationSelectOption: React.FC<{ organization: Organization; onSelect: (org: Organization) => any }> = ({
  organization,
  onSelect,
}) => (
  <UserSelectOptionWrapper
    onClick={e => {
      e.stopPropagation()
      onSelect(organization)
    }}>
    <UserSelectOptionInfoWrapper>
      <UserSelectOptionNameSpan>{`${organization.name}`}</UserSelectOptionNameSpan>
      <span>{organization.allParents.map(parent => parent.name).join(' / ')}</span>
    </UserSelectOptionInfoWrapper>
    <UserSelectOptionSelectWrapper>
      <Button>
        Assign <EnterOutlined />
      </Button>
    </UserSelectOptionSelectWrapper>
  </UserSelectOptionWrapper>
)

const UserSelectOptionWrapper = styled.div`
  height: 52px;
  padding: 8px;
  background: #fff;
  :hover {
    background: #7c6f64;
  }
  display: flex;
  flex-direction: row;
  cursor: default;
`

const UserSelectOptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const UserSelectOptionNameSpan = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`

const UserSelectOptionSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify: center;
`

const OrganizationList = styled.div`
  max-height: 400px;
  overflow: auto;
`

const OrganizationListWrapper = styled.div`
  margin-top: 15px;
`

const OrganizationUserRoleRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`
const RoleRadio = styled(Radio)`
  font-size: 12px !important;
`

const OrganizationUserBtnDeleteWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`

const RemoveUserButton = styled(Button)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  font-size: 10px;
`

const SelectAllRoot = styled.div`
  text-align: right;
`

const SelectAllRootButton = styled(Button)`
  font-size: 12px;
`
