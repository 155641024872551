import React from 'react'
import { useNavigate } from 'react-router'
import { usePlaneIssuesQuery } from '../../../api/hooks/aperture/issues'
import { CreateItemCard } from '../components/CreateItemCard'
import { HomeCarousel } from '../components/HomeCarousel'
import { HomeSection } from '../components/HomeSection'
import { PlaneIssueCard } from './PlaneIssueCard'
import { useLoadPlaneMe } from './useLoadPlaneMe'

export const PlaneIssues: React.FC = () => {
  const navigate = useNavigate()

  const { loading: meLoading, planeMe } = useLoadPlaneMe()
  const { data: issuesResp, loading: issueLoading } = usePlaneIssuesQuery({
    variables: {
      args: {
        assignees: planeMe?.id,
        perPage: 6,
        orderBy: '-created_at',
        expand: 'project,state',
        subIssue: false,
      },
    },
    skip: !planeMe,
  })

  return (
    <HomeSection title="Initiatives" showAllLink={'/aperture/collaboration/workspace-views/assigned'}>
      <HomeCarousel
        loading={meLoading || issueLoading}
        getRowKey={item => item.id}
        items={issuesResp?.planeIssues.results || []}
        renderItem={item => <PlaneIssueCard issue={item} />}
        slidesToShow={3}
        extraItem={
          <CreateItemCard
            title="Add an Initiative"
            description="Collaborate with colleagues and track your progress"
            onClick={() => navigate('/aperture/collaboration?create-issue=true')}
          />
        }
      />
    </HomeSection>
  )
}
